@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100&family=Quicksand:wght@300..700&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl font-bold;
  }
  h2 {
    @apply text-3xl font-bold;
  }
  h3 {
    @apply text-2xl font-bold;
  }
}

@layer components {
  .menu-item {
    @apply relative flex h-full items-center justify-center p-4 cursor-pointer font-bold text-pink-200 hover:text-zinc-200
        hover:bg-white/10 transition-colors ease-in-out;
  }
}

@layer utilities {
  .no-scrollbar {
    overflow-y: scroll; /* Keeps layout consistent */
    scrollbar-width: none; /* Firefox */
  }

  .no-scrollbar::-webkit-scrollbar {
    width: 0px; /* Chrome, Safari, Edge */
    background: transparent;
  }
}
